
import Card from "@/components/base/card/Card"
export default {
    name: "Snackbar",
    components: { Card },
    emits: ["input"],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        zIndex: {
            type: [Number, String],
            default: 1,
        },
    },
    methods: {
        onClickOutside() {
            this.$emit("input", false)
        },
    },
}
