
import Snackbar from "@/components/base/modal/Snackbar"
import Button from "@/components/base/Button"
import { mdiAlertCircle } from "@mdi/js"

export default {
    name: "SnackbarBanner",
    components: { Snackbar, Button },
    model: {
        prop: "errorMsg",
        event: "close",
    },
    props: {
        errorMsg: {
            type: String,
            default: "",
        },
    },
    icons: {
        mdiAlertCircle,
    },
}
